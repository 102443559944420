import { newOrderPillData } from '../utils/newOrderPillData';

export function fetchSearchResultsError(
	errors: API.ErrorMessage[],
): Search.Actions.FetchSearchResultsError {
	return {
		type: 'FETCH_SEARCH_RESULTS_ERROR',
		payload: { errors },
	};
}

interface PillsResponse {
	pills: string[];
	sidebar: string[];
}

type PillLimit = 2 | 4 | 5;
const getPillsFromDictionary = (
	dictionary: FilterPills.Filter[],
	maxFilterCategories: PillLimit,
): PillsResponse => {
	const sidebar: string[] = [];
	const pills: string[] = [];
	dictionary.forEach(({ filterName, mode }, pillIndex) => {
		if (mode === 'Pill' && pillIndex < maxFilterCategories) {
			pills.push(filterName);
		} else {
			sidebar.push(filterName);
		}
		while (pills.length < maxFilterCategories && sidebar.length) {
			pills.splice(1, 0, sidebar.pop());
		}
	});

	return { pills, sidebar };
};

const getPillsFromFilters = (
	filters: Search.FilterDetails[],
	maxFilterCategories: PillLimit,
): PillsResponse => {
	const sidebar: string[] = [];
	const pills: string[] = [];

	filters.forEach(({ name }, index) => {
		if (index < maxFilterCategories) {
			pills.push(name);
		}

		sidebar.push(name);
	});

	return { pills, sidebar };
};

export interface ReceiveFilterPillsOptions {
	categoryCode: FilterPills.VendorCategories;
	filters: Search.FilterDetails[];
	mobileUI: boolean;
	desktopLimit?: Search.FilterPillLimit;
}

export const receiveFilterPills = ({
	categoryCode,
	filters,
	mobileUI,
	desktopLimit,
}: ReceiveFilterPillsOptions): Search.Actions.ReceiveFilterPills => {
	const dictionary = newOrderPillData(mobileUI)[categoryCode];
	const maxFilterCategories = (mobileUI ? 0 : desktopLimit - 1) as PillLimit; // not including 'more filters'

	const { pills, sidebar } = dictionary
		? getPillsFromDictionary(dictionary, maxFilterCategories)
		: getPillsFromFilters(filters, maxFilterCategories);

	return {
		type: 'filters/RECEIVE_FILTER_PILLS',
		limit: (maxFilterCategories + 1) as Search.FilterPillLimit, // includes "More Filters"
		pills,
		sidebar,
	};
};

export const addAppliedFilterPillOptions = (
	filters: Search.AppliedFilterPillProps[],
): Search.Actions.AddAppliedFilterPillOptions => ({
	type: 'ADD_APPLIED_FILTER_PILL_OPTIONS',
	payload: {
		options: filters,
	},
});

export const removeAppliedFilterPillOptions = (
	filters: Search.AppliedFilterPillProps[],
): Search.Actions.RemoveAppliedFilterPillOptions => ({
	type: 'REMOVE_APPLIED_FILTER_PILL_OPTION',
	payload: {
		options: filters,
	},
});

export const commitAppliedFilterPillChanges =
	(): Search.Actions.CommitAppliedFilterPillChanges => ({
		type: 'COMMIT_APPLIED_FILTER_PILL_CHANGES',
	});

export const resetStagedFilterPillChanges =
	(): Search.Actions.ResetStagedFilterPillChanges => ({
		type: 'RESET_STAGED_FILTER_PILL_CHANGES',
	});

export const clearAppliedFilterPillOptions =
	(): Search.Actions.ClearAppliedFilterPillOptions => ({
		type: 'CLEAR_APPLIED_FILTER_PILL_OPTIONS',
	});

export const setFilterPillLimit = (
	limit: Search.FilterPillLimit,
): Search.Actions.SetFilterPillLimit => ({
	type: 'SET_FILTER_PILL_LIMIT',
	payload: limit,
});

export const setIsSidebarOpen = (
	bool: boolean,
): Search.Actions.SetIsSidebarOpen => ({
	type: 'SET_IS_SIDEBAR_OPEN',
	payload: bool,
});

export const modifySelected = (
	selected: string[],
): Search.Actions.ModifySelected => ({
	type: 'MODIFY_SELECTED',
	payload: selected,
});

export const modifyApplied = (
	applied: Search.AppliedFilterPillProps[],
): Search.Actions.ModifyApplied => ({
	type: 'MODIFY_APPLIED',
	payload: applied,
});

export const setClearSidebarFlag = (
	flag: string,
): Search.Actions.SetClearSidebarFlag => ({
	type: 'SET_CLEAR_SIDEBAR_FLAG',
	payload: flag,
});

export const setAvailableSpace = (
	availableSpace: number,
	item: keyof Search.Sizes,
): Search.Actions.SetAvailableSpace => ({
	type: 'filters/SET_AVAILABLE_SPACE',
	availableSpace,
	item,
});

export const setMaxResultColumns = (
	limit: Search.MaxResultColumnCount,
): Search.Actions.SetMaxResultColumns => ({
	type: 'SET_MAX_RESULT_COLUMNS',
	payload: limit,
});

export const clearSidebarFilters = (): Search.Actions.ClearSidebarFilters => ({
	type: 'CLEAR_SIDEBAR_FILTERS',
});

export const resetStagedResultsCount =
	(): Search.Actions.ResetStagedResultsCount => ({
		type: 'filters/RESET_STAGED_RESULTS_COUNT',
	});
